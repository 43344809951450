body {
  background-color: #f0f2f5;
  font-family: Arial, sans-serif;
}

.email-box {
  position: absolute;
  width: 100%;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-body {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.email-body h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.email-body .ant-input {
  margin-bottom: 10px;
  border-radius: 5px;
}

.email-body .ant-space-compact {
  display: flex;
  justify-content: space-between;
}

.email-body .ant-btn {
  border-radius: 5px;
}

.email-button {
  display: block;
  width: 100%;
  margin-top: 20px;
  background-color: #1890ff;
  border-color: #1890ff;
}

.email-button:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.weixin-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  font-size: 18px;
  z-index: 1000;
}

