.confirmation-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0px; /* 减少内边距 */
    font-family: 'Arial', sans-serif;
}

.confirmation-card {
    width: 100%;
    max-width: 800px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 0; /* 减小卡片内边距 */
    transition: transform 0.3s ease-in-out;
}

.confirmation-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: -5px;
    margin-bottom: 10px; /* 减少外边距 */
}

.confirmation-descriptions {
    margin-bottom: 0; /* 减少外边距 */
    margin-left: -10px; /* 减少外边距 */
    margin-right: -10px; /* 减少外边距 */
}

.confirmation-descriptions .ant-descriptions-item-label {
    width: 120px; /* 设置标题列的宽度 */
}

.confirmation-buttons .edit-button, .confirmation-buttons .submit-button {
    border-radius: 5px;
    font-weight: bold;
}

.confirmation-buttons .edit-button {
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    margin-bottom: 10px; /* 减少按钮之间的外边距 */
}

.confirmation-buttons .edit-button:hover {
    background-color: #ff7875;
}

.confirmation-buttons .submit-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px; /* 设置字体大小为 14px */
}

.confirmation-buttons .submit-button:hover {
    background-color: #66bb6a;
}

.no-data {
    text-align: center;
    font-size: 18px;
    color: #999;
    margin-top: 20px; /* 减少外边距 */
}
