.form_body {
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-form {
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-item {
    margin-bottom: 24px;
}

.form-item-gender,
.form-item-image-type,
.form-item-upload,
.form-item-date,
.form-item-submit {
    margin-bottom: 24px;
}

.upload-button {
    width: 100%;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #40a9ff;
}

.confirmation-modal {
    padding: 20px;
}

.confirmation-page {
    padding: 20px;
}

.confirmation-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirmation-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.confirmation-descriptions {
    font-size: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 12px 16px;
}

.confirmation-edit-button,
.confirmation-submit-button {
    margin: 0 10px;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    border: none;
}

.confirmation-edit-button {
    background-color: #faad14;
    color: #fff;
}

.confirmation-submit-button {
    background-color: #52c41a;
    color: #fff;
}

.confirmation-edit-button:hover {
    background-color: #ffc53d;
}

.confirmation-submit-button:hover {
    background-color: #73d13d;
}

.date-select-item {
    margin-bottom: 24px;
}

.date-select-year,
.date-select-month,
.date-select-day {
    width: 32%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
}

.input-group-compact > *:first-child,
.input-group-compact > *:last-child {
    border-radius: 4px;
}

.input-group-compact > * {
    border: 1px solid #d9d9d9;
}
