.form_body {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.form_body h3 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  color: #333;
}

.form-item {
  margin-bottom: 20px;
}

.form-item label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
  font-size: 18px;
}

.form-item .required {
  color: red;
  margin-right: 5px;
}

.inline-inputs {
  display: flex;
  justify-content: space-between;
}

.inputBox {
  width: 100%;
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
}

.ant-radio-group .ant-radio-wrapper {
  display: inline-block;
  margin-right: 20px; /* 根据需要调整间距 */
}

.ant-upload-list-item {
  width: auto;
}

.ant-upload-list-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-button {
  background-color: #1677ff;
  color: white;
  font-size: 18px;
  padding: 0 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #125eb2;
}

.signature-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.signature-box {
  border: 1px dashed #1677ff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.signature-canvas {
  width: 100% !important;
  height: 200px !important;
}

.ant-select-selector {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  height: 40px;
  font-size: 16px;
}

.ant-select-selector .ant-select-selection-search-input {
  width: 100% !important;
}

.bank-name-suggested {
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #1677ff;
}

.bank-name-suggested li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.bank-name-suggested li:hover {
  background: #f0f0f0;
}

.pdf-links div {
  margin-bottom: 10px;
}

.pdf-links a {
  color: #1677ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.pdf-links a:hover {
  color: #125eb2;
}

.clear-button {
  margin-bottom: 10px;
}
