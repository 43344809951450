.form-group {
    margin: 20px 0;
    font-family: 'Arial', sans-serif;
}

label {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
    color: #333;
}

.notice-container {
    overflow: hidden;
    max-height: 50px; /* 半隐藏状态的最大高度 */
    transition: max-height 0.5s ease;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notice-container.expanded {
    max-height: none; /* 显示全部内容 */
}

.notice-container p {
    margin: 10px 0;
    line-height: 1.6;
    color: #555;
}

.notice-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.notice-container h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #444;
}

.notice-container ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    padding-left: 20px;
}

.notice-container li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
}

.toggle-button {
    display: block;
    margin-top: 10px;
    cursor: pointer;
    color: #007bff;
    background: none;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    padding: 0;
    font-weight: bold;
    transition: color 0.3s ease;
}

.toggle-button:hover {
    color: #0056b3;
}

.responsive-img {
    width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
}
