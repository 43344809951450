.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: center/cover url('~@/assets/images/login.png');

  .login-header {
    // width: 200px;
    // height: 60px;
    display: flex;
    justify-content: center;
  }

  .login-logo {
    position: relative;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }

  .login-title {
    text-align: center;
    font-size: 1.5em; // 增大字体
    line-height: 1.5; // 调整行高
    font-weight: 800;
    color: #303030;
    margin-top: 20px; // 增加上边距
    margin-bottom: 20px; // 增加下边距
    padding: 10px; // 增加内边距
  }

  .login-container {
    width: 440px;
    height: 360px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
  }

  .login-checkbox-label {
    color: #1890ff;
  }

  .login-footer {
    text-align: center;
    font-size: 0.8em;
    line-height: 1.5; // 调整行高
    font-weight: 800;
    color: #999999;
    margin-bottom: 20px; // 增加下边距
    padding: 10px; // 增加内边距
  }
}