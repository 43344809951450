.invalid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffe6e6;
    text-align: center;
}

.invalid-content {
    background: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
}

.invalid-title {
    font-size: 2rem;
    color: #ff0000;
    margin-bottom: 20px;
}

.invalid-message {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff0000;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #e60000;
}
