.ant-layout {
  height: 100%;
}

.header {
  padding: 0;
}

.logo {
  width: 165px;
  height: 64px;
 }

.layout-content {
  overflow-y: auto;
}

.user-info {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 20px;
  color: #fff;

  .user-name {
    margin-right: 20px;
  }

  .user-logout {
    display: inline-block;
    cursor: pointer;
  }
}

.ant-layout-header {
  padding: 0 !important;
}

.layout-title {

  text-align: left;
  font-size: medium;
  line-height: 70px;
  padding-left: 10px;
  font-weight: 600;
  color: #ffffff;
}