.submitted-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f8ff;
    text-align: center;
}

.submitted-content {
    background: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
}

.submitted-title {
    font-size: 2.5rem;
    color: #4caf50;
    margin-bottom: 20px;
}

.submitted-message {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #43a047;
}
